// import classNames from 'classnames';
// import { useId } from 'hooks';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  // const id = useId();
  // const clipId = `monogram-clip-${id}`;

  return (
    // <svg
    //   aria-hidden
    //   className={classNames('monogram', className)}
    //   width="46"
    //   height="29"
    //   viewBox="0 0 46 29"
    //   {...props}
    // >
    //   <defs>
    //     <clipPath id={clipId}>
    //       <path d="M16.525 28.462l7.18-18.35.003-.001 9.72 18.442a.838.838 0 001.524-.093l3.39-8.824a.846.846 0 00-.04-.686L30.307 3.605A6.698 6.698 0 0024.367 0h-4.6a.84.84 0 00-.74 1.23l3.63 6.887-3.655 9.15-7.12-13.662A6.698 6.698 0 005.942 0h-4.6a.842.842 0 00-.748 1.23L15 28.554a.839.839 0 001.524-.092zM42.392 8.806a.835.835 0 00.387-.446v.001l2.67-7.23a.838.838 0 00-.785-1.129h-6.578a.837.837 0 00-.736 1.238l3.907 7.226c.22.41.729.56 1.135.34z" />
    //     </clipPath>
    //   </defs>
    //   <rect clipPath={`url(#${clipId})`} width="100%" height="100%" />
    //   {highlight && (
    //     <g clipPath={`url(#${clipId})`}>
    //       <rect className="monogram__highlight" width="100%" height="100%" />
    //     </g>
    //   )}
    // </svg>
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 362.8 362.8" className="enableBG">
      <g id="Layer_3" className="st0"></g>
      <g id="Layer_2"></g>
      <g id="Layer_1">
        <g>
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="44.4951"
            y1="128.2232"
            x2="181.6425"
            y2="128.2232"
          >
            <stop offset="1" className="st-color0" />
            <stop offset="1" className="st-color1" />
          </linearGradient>
          <polygon
            className="st1"
            points="44.5,170.4 181.6,114.9 181.6,86.1 44.5,141.6    "
          />
          <linearGradient
            id="SVGID_2_"
            gradientUnits="userSpaceOnUse"
            x1="44.1522"
            y1="187.8823"
            x2="181.2996"
            y2="187.8823"
          >
            <stop offset="1" className="st-color0" />
            <stop offset="1" className="st-color1" />
          </linearGradient>
          <polygon
            className="st2"
            points="44.2,230.1 181.3,174.5 181.3,145.7 44.2,201.3     "
          />
          <linearGradient
            id="SVGID_3_"
            gradientUnits="userSpaceOnUse"
            x1="181.2996"
            y1="126.1483"
            x2="318.447"
            y2="126.1483"
          >
            <stop offset="0" className="st-color0" />
            <stop offset="1" className="st-color1" />
          </linearGradient>
          <path
            className="st3"
            d="M306.6,165.6l-125.3-50.8V86.1l124.6,50.5c7.6,3.1,12.5,10.4,12.5,18.6v2.5
        C318.4,163.7,312.3,167.9,306.6,165.6z"
          />
          <linearGradient
            id="SVGID_4_"
            gradientUnits="userSpaceOnUse"
            x1="181.2996"
            y1="185.6281"
            x2="318.447"
            y2="185.6281"
          >
            <stop offset="0" className="st-color0" />
            <stop offset="1" className="st-color1" />
          </linearGradient>
          <path
            className="st4"
            d="M305.6,224.9l-124.3-50.3v-28.8L305.5,196c7.8,3.2,12.9,10.8,12.9,19.2v1
          C318.4,222.8,311.7,227.3,305.6,224.9z"
          />
          <linearGradient
            id="SVGID_5_"
            gradientUnits="userSpaceOnUse"
            x1="44.1643"
            y1="243.4351"
            x2="181.3117"
            y2="243.4351"
          >
            <stop offset="0" className="st-color1" />
            <stop offset="1" className="st-color1" />
          </linearGradient>
          <polygon
            className="st3"
            points="181.3,285.6 44.2,230.1 44.2,201.3 181.3,256.8    "
          />
          <linearGradient
            id="SVGID_6_"
            gradientUnits="userSpaceOnUse"
            x1="44.1643"
            y1="183.776"
            x2="318.447"
            y2="183.776"
          >
            <stop offset="0" className="st-color1" />
            <stop offset="1" className="st-color0" />
          </linearGradient>
          <path
            className="st4"
            d="M181.3,225.9L44.2,170.4v-28.8l137.1,55.5V225.9z M181.3,225.9l126.6-51.3c6.4-2.6,10.5-8.7,10.5-15.6v-5.4
              c0-5.7-5.8-9.7-11.1-7.5l-126,51V225.9z"
          />
          <linearGradient
            id="SVGID_7_"
            gradientUnits="userSpaceOnUse"
            x1="181.2996"
            y1="246.2956"
            x2="318.447"
            y2="246.2956"
          >
            <stop offset="0" className="st-color1" />
            <stop offset="1" className="st-color0" />
          </linearGradient>
          <path
            className="st5"
            d="M181.3,285.6l126.8-51.4c6.3-2.5,10.4-8.6,10.4-15.4v0c0-8.4-8.5-14.2-16.3-11l-120.8,48.9V285.6z"
          />
          <linearGradient
            id="SVGID_8_"
            gradientUnits="userSpaceOnUse"
            x1="294.2935"
            y1="208.4999"
            x2="318.6346"
            y2="208.4999"
          >
            <stop offset="1" className="st-color0" />
            <stop offset="1" className="st-color1" />
          </linearGradient>
          <path
            className="st6"
            d="M294.3,211c6.9-2.7,9.2-3.9,12.5-5.2s4.8-2.9,1.3-6c-3.5-3.1-6.8-4.5-6.8-4.5l-6.1-3.4l10,4.1
                  c0,0,0.6,0.2,2.6,1.2c1.9,1,2.4,1.7,3,2s3.7,3.6,4.2,4.6c0.5,1,1.2,1.7,2.1,4s1.1,3,1.4,6c0.3,3.1,0.2,4.8-0.1,6.5
                  c-0.3,1.8-19,4.8-19,4.8l-0.6-2.2L294.3,211z"
          />
          <linearGradient
            id="SVGID_9_"
            gradientUnits="userSpaceOnUse"
            x1="294.3414"
            y1="148.7631"
            x2="318.6824"
            y2="148.7631"
          >
            <stop offset="1" className="st-color0" />
            <stop offset="1" className="st-color1" />
          </linearGradient>
          <path
            className="st7"
            d="M294.3,151.2c6.9-2.7,9.2-3.9,12.5-5.2s4.8-2.9,1.3-6s-6.8-4.5-6.8-4.5l-6.2-3.3l10.1,3.9
                    c0,0,0.6,0.2,2.6,1.2c1.9,1,2.4,1.7,3,2c0.6,0.3,3.7,3.6,4.2,4.6c0.5,1,1.2,1.7,2.1,4s1.1,3,1.4,6s0.2,4.8-0.1,6.5
                    c-0.3,1.8-19,4.8-19,4.8l-0.6-2.2L294.3,151.2z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Monogram;
