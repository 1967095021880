export const navLinks = [
  {
    label: 'Demo',
    pathname: '/projects',
    hash: '',
  },
  {
    label: 'Services',
    pathname: '/',
    hash: '#services',
  },
  {
    label: 'Details',
    pathname: '/',
    hash: '#details',
  },
];

export const socialLinks = [
  {
    label: 'E-mail us',
    url: 'mailto:info@xbuild.in',
    icon: 'mail',
  },
  {
    label: 'Call us',
    url: 'tel:+918179567313',
    icon: 'phone',
  },
  {
    label: 'linkedin',
    url: 'https://www.linkedin.com/company/xbuild-technologies',
    icon: 'linkedin',
  },
];
